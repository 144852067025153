@if (customFieldList$ | async; as systemBankList) {
  @if (systemBankList.length !== 0) {
    <offconon-shared-ui-virtualscroll-dropdown
      [selectOptions]="(customFieldList$ | async) || []"
      [optionTemplate]="optionTemplate"
      [selectedOptionTemplate]="selectedOptionTemplate"
      [selectedOption]="$any(commonDropdownAndMultiselectService.selectedOptions$) | async"
      (selectedOptionOutput)="select($event)"
      (paginationAndSearchOutput)="paginationAndSearchOutput($event)">
      <ng-template #optionTemplate let-item="item">
        <div class="ml-1">
          <span class="block mb-1 text-sm font-medium white-space-nowrap">
            {{ item.name }}
          </span>
          <div class="pb-3">
            <div class="w-full leading-6">
              {{ 'Custom field type' | translate }}:
              <span class="text-blue-500">
                {{ item.custom_type }}
              </span>
            </div>
            <div class="w-full leading-6">
              {{ item.description }}
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #selectedOptionTemplate let-item="item">
        @if (item) {
          <div class="ml-1">
            <span class="block mb-1 text-sm font-medium white-space-nowrap">
              {{ item.name }}
            </span>
            <div class="pb-3">
              <div class="w-full leading-6">
                {{ 'Custom field type' | translate }}:
                <span class="text-blue-500">
                  {{ item.custom_type }}
                </span>
              </div>
              <div class="w-full leading-6">
                {{ item.description }}
              </div>
            </div>
          </div>
        }
      </ng-template>
    </offconon-shared-ui-virtualscroll-dropdown>

    @if (createNewOne) {
      <small class="block cursor-pointer mt-1 leading-6" (click)="createNew()">
        {{ 'You can create a new one here.' | translate }}
        <span class="text-blue-500">{{ 'Click here' | translate }}</span>
      </small>
    }
  }

  @if (systemBankList.length === 0) {
    <div class="flex flex-col gap-2 mb-4">
      <div class="mt-3 text-center">
        <div class="block cursor-pointer text-red-500" (click)="createNew()">
          {{ 'We did not find a bank in our database in the selected country.' | translate }}
          <span class="text-blue-500">{{
            'Click here to add your bank to our system.' | translate
          }}</span>
        </div>
      </div>
    </div>
  }
}

<p-drawer
  #creatorSideBarRef
  [fullScreen]="true"
  [appendTo]="'body'"
  [modal]="false"
  [closeOnEscape]="true"
  [showCloseIcon]="false"
  [(visible)]="creatorSideBar">
  <div class="flex justify-between">
    <div class="text-primary font-light text-xl">{{ 'Create' | translate }}</div>
    <div>
      <p-button
        icon="pi pi-times"
        severity="danger"
        [rounded]="true"
        [text]="true"
        (click)="getClosePanel()" />
    </div>
  </div>

  <p-divider class="w-full" />

  <div class="p-2">
    <div class="pt-3">
      @if (creatorSideBar) {
        <offconon-custom-fields-form [workflow_place]="true" (formSavedEvent)="savedForm($event)" />
      }
    </div>
  </div>
</p-drawer>
