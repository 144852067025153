import { AsyncPipe, CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { Drawer, DrawerModule } from 'primeng/drawer';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';

import { CommonCustomFieldCategory, SystemService } from '@offconon/core-api';
import { CustomFieldsFormComponent } from '@offconon/shared/ui/custom-fields-form';
import { SharedUiVirtualscrollDropdownComponent } from '@offconon/shared/ui/shared-ui-virtualscroll-dropdown';
import { CommonSelectOption } from '@offconon/shared/ui/shared-ui-virtualscroll-multiselect';
import {
  CommonDropdownAndMultiselectService,
  CustomFieldHelperService,
  DropdownData,
} from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-custom-fields-common-virtual-scroll-dropdown',
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,
    SharedUiVirtualscrollDropdownComponent,
    AsyncPipe,
    DrawerModule,
    TranslateModule,
    CustomFieldsFormComponent,
  ],
  providers: [CommonDropdownAndMultiselectService],
  templateUrl: './custom-fields-common-virtual-scroll-dropdown.component.html',
  styles: ``,
})
export class CustomFieldsCommonVirtualScrollDropdownComponent implements OnInit, OnChanges {
  @Input({ required: true }) customFieldFormControl: any;
  @Input() parameters: any;
  @Input() selectedBusiness: any;
  @Input() selectFirst = true;
  @Input() selectedId: number;
  @Input() createNewOne = true;
  @Output() selectedCustomFieldEvent = new EventEmitter<any>();
  @ViewChild('creatorSideBarRef') creatorSideBarRef!: Drawer;

  creatorSideBar = false;

  public commonDropdownAndMultiselectService = inject(CommonDropdownAndMultiselectService);
  private systemService = inject(SystemService);
  private customFieldHelperService = inject(CustomFieldHelperService);

  customFieldList$: Observable<CommonSelectOption[]>;

  dropdownData$ = new BehaviorSubject<
    DropdownData<{
      id?: number;
      business_id?: any;
      name?: string;
      filter?: any;
      category_types?: any;
    }>
  >({ page: 1, pageSize: 50, dataList: [] });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['parameters']) {
      this.dropdownData$.next({
        page: 1,
        pageSize: 50,
        searchJson: {
          business_id: this.parameters?.business_id,
          filter: this.parameters?.filter ? this.parameters?.filter : 'all',
          category_types: [this.parameters.custom_filed_service_type],
        },
        selectedId: undefined,
        dataList: [],
      });
    }
  }

  ngOnInit(): void {
    this.initializeComponent();

    this.commonDropdownAndMultiselectService.selectedOptions$.subscribe({
      next: (option: any) => {
        if (option) {
          this.customFieldFormControl.patchValue(option.id);
          this.selectedCustomFieldEvent.emit(option);
        }
      },
    });
  }

  private initializeComponent() {
    this.customFieldList$ = this.commonDropdownAndMultiselectService.fetchData<
      SystemService,
      {
        id?: number;
        business_id?: any;
        name?: string;
        filter?: any;
        category_types?: any;
      }
    >({
      service: this.systemService,
      action: 'systemCustomFieldList',

      dropdownData: this.dropdownData$,
      mapData: (data: CommonCustomFieldCategory[]) => {
        return data?.map((item) => {
          return {
            name: item?.keyword?.translations ? item?.keyword?.translations[0]?.translation : '',
            id: item.id,
            custom_type: this.getCustomTranslate(item.custom_type),
            description: item?.keyword?.translations
              ? item?.keyword?.translations[0]?.description
              : '',
            additionalData: item,
          };
        });
      },
      selectFirst: this.selectFirst,
    });

    this.dropdownData$.next({
      page: 1,
      pageSize: 50,
      selectedId: this.selectedId,
      searchJson: {
        business_id: this.parameters?.business_id,
        filter: this.parameters?.filter ? this.parameters?.filter : 'all',
        category_types: [this.parameters.custom_filed_service_type],
      },
      dataList: [],
    });
  }

  updatecustomFieldFormControl(states: CommonSelectOption[] = []) {
    const customFieldFormControl = this.customFieldFormControl;

    if (states?.length === 0) {
      customFieldFormControl.clearValidators();
      customFieldFormControl.disable();
    } else {
      customFieldFormControl.setValidators([Validators.required]);
      customFieldFormControl.enable();
    }
    customFieldFormControl.updateValueAndValidity();
  }

  select(event: any) {
    this.customFieldFormControl.patchValue(event.id);
    this.selectedCustomFieldEvent.emit(event);
  }

  paginationAndSearchOutput(event: any) {
    lastValueFrom(this.customFieldList$).then((data) => {
      this.updatecustomFieldFormControl(data);
    });
    this.commonDropdownAndMultiselectService.onDataChange(event, this.dropdownData$, {
      keyword_translation: event.searchString,
      business_id: this.parameters?.business_id,
      filter: this.parameters?.filter ? this.parameters?.filter : 'all',
      category_types: [this.parameters.custom_filed_service_type],
    });
  }

  getCustomTranslate(itemdata: any) {
    return this.customFieldHelperService.customTypeEnums[itemdata];
  }

  getClosePanel() {
    this.creatorSideBar = false;
  }

  createNew() {
    this.creatorSideBar = true;
  }

  savedForm(data: any) {
    if (data) {
      this.dropdownData$.next({
        page: 1,
        pageSize: 50,
        searchJson: {
          business_id: this.parameters?.business_id,
          filter: this.parameters?.filter ? this.parameters?.filter : 'all',
          category_types: [this.parameters.custom_filed_service_type],
        },
        selectedId: data.id,
        dataList: [],
      });
    }
    this.getClosePanel();
  }
}
